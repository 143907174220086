import { Url } from "@edgetier/types";
import { useChatEmailToggles } from "hooks-for/users/use-chat-email-toggles";
import useUserStates from "hooks-for/users/use-user-states";
import { useCallback, useContext, useEffect } from "react";
import { useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { IUser, UserState } from "redux/application.types";
import ChatEvent from "redux/modules/chat/chat-event";
import { getSocket } from "redux/modules/chat/socket";
import { IApplicationState } from "redux/types";
import urljoin from "url-join";
import UserStateContext from "../../user-state-context";

/**
 * This hook will listen for the UserStateUpdate event and update the user state cache and toggle chat and/or email.
 * @param user The user whose state is being updated.
 */
export const useUserStateUpdateListener = (user: IUser | undefined) => {
    const queryClient = useQueryClient();
    const { data: userStates } = useUserStates();

    const { toggleChat, toggleEmail } = useChatEmailToggles();

    const { setIsUserStateLoading, setUserStateId } = useContext(UserStateContext);

    const hasBeenForceDisabled = useSelector((state: IApplicationState) => state.chat.hasBeenForceDisabled);

    /**
     * The callback function for the UserStateUpdate event. This function will update the user state cache and
     * toggle chat and/or email if necessary.
     * @param user        The user whose state is being updated.
     * @param userStateId The new user state.
     */
    const updateUserState = useCallback(
        (user: IUser) =>
            ({ userStateId }: { userStateId: UserState; updateDateTime: Date }) => {
                const userState = (userStates ?? []).find((state) => state.userStateId === userStateId);
                queryClient.setQueryData<IUser>(Url.UsersMe, (cachedUser) => {
                    return { ...(cachedUser ?? user), userStateId };
                });
                queryClient.setQueryData<IUser>(urljoin(Url.Users, user.userId.toString()), (cachedUser) => {
                    return { ...(cachedUser ?? user), userStateId };
                });

                // If the user has been force disabled, make sure that chat remains disabled.
                toggleChat(hasBeenForceDisabled ? false : userState?.isChatEnabled ?? false);
                toggleEmail(userState?.isEmailEnabled ?? false);

                setUserStateId(userStateId);
                setIsUserStateLoading(false);
            },
        [hasBeenForceDisabled, queryClient, setIsUserStateLoading, setUserStateId, toggleChat, toggleEmail, userStates]
    );

    useEffect(() => {
        const createSocketEventListener = async () => {
            if (typeof user === "undefined") {
                throw new Error("User is undefined");
            }

            const socket = await getSocket();
            // This listener has to update every time user or userStates changes, so remove the previous listener
            // before adding a new one.
            socket.on(ChatEvent.UserStateUpdate, updateUserState(user));
        };
        if (typeof user !== "undefined" && typeof userStates !== "undefined") {
            createSocketEventListener();
        }

        return () => {
            (async () => {
                const socket = await getSocket();
                socket.off(ChatEvent.UserStateUpdate);
            })();
        };
    }, [updateUserState, user, userStates]);
};
