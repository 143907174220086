import classNames from "classnames";
import React, { useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DropDownMenuContext from "../drop-down-menu-context";

import { IProps } from "./drop-down-menu-item.types";

/**
 * Item in the drop-down menu.
 * @param props.isDisabled If the item should be displayed or not.
 * @param props.children   Drop down item content.
 * @param props.onClick    Function that will run when an item is clicked.
 * @param props.icon       An optional icon that can be added to the item.
 */
const DropDownMenuItem = ({ isDisabled = false, children, onClick, icon }: IProps) => {
    const dropDownMenuContext = useContext(DropDownMenuContext);

    const onItemSelect = () => {
        if (isDisabled === false) {
            onClick();
            dropDownMenuContext.toggleIsOpen();
        }
    };

    /**
     * Render a drop-down menu item that may or may not be disabled.
     * @returns List item.
     */
    return (
        <li className={classNames({ "drop-down-menu--disabled": isDisabled })} onClick={onItemSelect}>
            {icon && <FontAwesomeIcon fixedWidth={true} icon={icon} />}
            {children}
        </li>
    );
};

export default DropDownMenuItem;
