import Role from "constants/role";
import PermittedHidden from "components/permitted-hidden";
import useShortcutScope from "components-for/shortcuts/use-shortcut-scope";
import logo from "images/icon-colour-light-background.svg";
import AgentHomeLink from "./agent-home-link";
import ChatLink from "./chat-link";
import CreateEmailLink from "./create-email-link";
import DeferredQueriesLink from "./deferred-queries-link";
import QueryLink from "./query-link";
import SignOut from "./sign-out/sign-out";
import TemplatesLink from "./templates-link";
import UsersLink from "./users-link";
import { IProps } from "./navigation.types";
import { CategoryNavigation, NavigationLink } from "@edgetier/client-components";
import {
    faAddressBook,
    faBan,
    faBuilding,
    faCalendar,
    faChartBar,
    faUsers,
    faUserTag,
} from "@fortawesome/pro-solid-svg-icons";
import { faCog, faComment, faCommentLines, faComments, faDesktop, faEnvelope } from "@fortawesome/pro-solid-svg-icons";
import { faFile, faFileChartLine, faGlobeAmericas, faListAlt, faPaintRoller } from "@fortawesome/pro-solid-svg-icons";
import { faSearch, faSitemap, faTags, faWrench } from "@fortawesome/pro-solid-svg-icons";
import View from "constants/view";
import classNames from "classnames";
import {
    FORMS_PAGE_ENABLED,
    LIVE_AGENTS_REPORTING_ENABLED,
    MANAGE_USER_STATES_ENABLED,
    PROACTIVE_CHAT_ENABLED,
} from "constants/feature-flags";
import "./navigation.scss";

/**
 * Display links to other routes. Certain links are only displayed depending on the user's role.
 */
const Navigation = ({
    callFunctionalityEnabled,
    chatFunctionalityEnabled,
    emailFunctionalityEnabled,
    proactiveChatEnabled,
}: IProps) => {
    useShortcutScope();

    return (
        <div className="navigation">
            <div
                className={classNames("navigation__logo", {
                    "navigation__logo--inverted": process.env.NODE_ENV !== "production",
                })}
            >
                <img src={logo} alt="EdgeTier" />
            </div>
            <div className="navigation__links">
                <PermittedHidden roleIds={[Role.Agent, Role.TeamLead]}>
                    <AgentHomeLink />
                </PermittedHidden>

                {emailFunctionalityEnabled && (
                    <PermittedHidden roleIds={[Role.Agent, Role.TeamLead]}>
                        <CreateEmailLink />
                    </PermittedHidden>
                )}

                {emailFunctionalityEnabled && (
                    <PermittedHidden roleIds={[Role.Agent, Role.TeamLead]}>
                        <QueryLink />
                    </PermittedHidden>
                )}

                {chatFunctionalityEnabled && (
                    <PermittedHidden roleIds={[Role.Agent, Role.TeamLead]}>
                        <ChatLink />
                    </PermittedHidden>
                )}
                {emailFunctionalityEnabled && (
                    <PermittedHidden roleIds={[Role.Agent, Role.TeamLead]}>
                        <DeferredQueriesLink />
                    </PermittedHidden>
                )}

                <PermittedHidden roleIds={[Role.Administrator, Role.TeamLead]}>
                    <CategoryNavigation label="Reporting" icon={faChartBar}>
                        {LIVE_AGENTS_REPORTING_ENABLED && (
                            <NavigationLink icon={faUsers} page={View.ReportingAgents} label="Active Agents" />
                        )}
                        {chatFunctionalityEnabled && (
                            <NavigationLink icon={faComments} page={View.ReportingChat} label="Chat Queue" />
                        )}
                        {emailFunctionalityEnabled && (
                            <NavigationLink icon={faEnvelope} page={View.ReportingQueue} label="Email Queue" />
                        )}
                        <NavigationLink icon={faCalendar} page={View.ReportingHistoric} label="Historic" />
                        <NavigationLink icon={faFileChartLine} page={View.ReportingReports} label="Reports" />
                        {emailFunctionalityEnabled && (
                            <NavigationLink icon={faDesktop} page={View.ReportingPublicEmail} label="Email Wallboard" />
                        )}
                        {chatFunctionalityEnabled && (
                            <NavigationLink icon={faDesktop} page={View.ReportingPublicChat} label="Chat Wallboard" />
                        )}
                        {callFunctionalityEnabled && (
                            <NavigationLink icon={faDesktop} page={View.CallWallboard} label="Call Wallboard" />
                        )}
                    </CategoryNavigation>
                </PermittedHidden>

                <CategoryNavigation icon={faSearch} label="Search">
                    <NavigationLink icon={faComments} page={View.SearchInteractions} label="Interactions" />
                    <NavigationLink icon={faAddressBook} page={View.SearchQueries} label="Queries" />
                </CategoryNavigation>

                <PermittedHidden roleIds={[Role.Administrator, Role.TeamLead]}>
                    <CategoryNavigation icon={faCog} label="Settings">
                        <PermittedHidden roleIds={[Role.Administrator]}>
                            {MANAGE_USER_STATES_ENABLED && (
                                <NavigationLink icon={faUserTag} page={View.SettingsUserStates} label="Agent States" />
                            )}
                        </PermittedHidden>

                        <NavigationLink icon={faListAlt} page={View.SettingsSetups} label="Chat Widgets" />
                        <PermittedHidden roleIds={[Role.Administrator]}>
                            <NavigationLink
                                icon={faComment}
                                page={View.SettingsChatWidgetAppearances}
                                label="Chat widget appearances"
                            />
                            <NavigationLink icon={faBan} page={View.SettingsBans} label="Chat Bans" />
                            <NavigationLink icon={faPaintRoller} page={View.SettingsThemes} label="Themes" />
                            <NavigationLink icon={faGlobeAmericas} page={View.SettingsLanguages} label="Languages" />
                            <NavigationLink
                                icon={faEnvelope}
                                page={View.SettingsEmailRoutingRules}
                                label="Email routing rules"
                            />
                            <NavigationLink icon={faBuilding} page={View.SettingsDepartments} label="Departments" />
                            <NavigationLink icon={faTags} page={View.SettingsSkills} label="Skills" />
                            {FORMS_PAGE_ENABLED && (
                                <NavigationLink icon={faFile} page={View.SettingsForms} label="Forms" />
                            )}
                            <NavigationLink icon={faWrench} page={View.SettingsSystem} label="System" />
                            <NavigationLink icon={faSitemap} page={View.LogicFlows} label="Flows" />
                        </PermittedHidden>
                    </CategoryNavigation>
                </PermittedHidden>
                {PROACTIVE_CHAT_ENABLED && proactiveChatEnabled && (
                    <NavigationLink icon={faCommentLines} page={View.ProactiveChat} label="Proactive Chat" />
                )}

                <TemplatesLink />

                <PermittedHidden roleIds={[Role.Administrator, Role.TeamLead]}>
                    <UsersLink />
                </PermittedHidden>

                <SignOut />
            </div>
        </div>
    );
};

export default Navigation;
