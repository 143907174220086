import React, { FunctionComponent, memo, useContext } from "react";
import { IProps } from "./dynamic-filter-field.types";
import LabeledField from "~/labeled-field";
import FieldInline from "~/field-inline";
import "./dynamic-filter-field.scss";
import { DynamicFiltersContext } from "../dynamic-filters-context/dynamic-filters-context";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useFormikContext } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/**
 * Filter fields wrapper, used to define a a filter.
 * @param props.children           Form field displayed
 * @param props.filterCanBeRemoved Boolean indicating if a filter can be removed.
 * @param props.icon               Icon for field
 * @param props.noWrap             Should the field be displayed as it is or wrapped to a inline field
 * @param props.name               The name of the field.
 * @param props.names              Optional altername names for the field.
 */
const DynamicFilterField: FunctionComponent<IProps> = ({
    children,
    filterCanBeRemoved = true,
    icon,
    noWrap,
    label,
    name,
    names: alternateNames,
}) => {
    const { canRemoveFilters, onRemoveFilter, setVisibleFields } = useContext(DynamicFiltersContext);
    const { setFieldValue } = useFormikContext();

    /**
     * Remove a filter from the visible dynamic filters. Unset the value in the form and call the onRemoveFilter
     * callback.
     */
    const removeFilter = () => {
        setVisibleFields((previousVisibleFields) => previousVisibleFields.filter((fieldName) => fieldName !== name));
        setFieldValue(name, undefined);

        // Some fields have alternate names which may also have values in the form. These need to be unset as well.
        if (Array.isArray(alternateNames)) {
            alternateNames
                .filter((alternateName) => alternateName !== name)
                .forEach((alternateName) => setFieldValue(alternateName, undefined));
        }

        onRemoveFilter(name, alternateNames);
    };

    return (
        <div className="dynamic-filter-field">
            {noWrap ? (
                <>{children}</>
            ) : (
                <LabeledField name={name} label={label}>
                    <FieldInline icon={icon}>{children}</FieldInline>
                </LabeledField>
            )}

            {canRemoveFilters && filterCanBeRemoved && (
                <div className="dynamic-filter-field__remove-button">
                    <button onClick={removeFilter} type="button">
                        <FontAwesomeIcon title="remove-filter" name="remove-filter" icon={faTimes} />
                    </button>
                </div>
            )}
        </div>
    );
};

export default memo(DynamicFilterField);
