import { doNothing } from "@edgetier/utilities";
import { createContext } from "react";
import { IDynamicFiltersContext } from "./dynamic-filters-context.types";

export const defaultContext: IDynamicFiltersContext = {
    canRemoveFilters: true,
    notVisibleFields: [],
    onRemoveFilter: doNothing,
    setVisibleFields: doNothing,
    visibleFields: [],
};

export const DynamicFiltersContext = createContext(defaultContext);
