import React, { FunctionComponent } from "react";
import classNames from "classnames";

import { IProps } from "./page-content.types";
import "./page-content.scss";

/**
 * Main content of a page.
 * @param props.children Page components.
 * @returns              Page content.
 */
const PageContent: FunctionComponent<IProps> = ({ children, width = "standard" }) => {
    return (
        <div className="page-content">
            <div
                className={classNames("page-content__container", {
                    [`page-content__container--${width}`]: typeof width === "string",
                })}
            >
                {children}
            </div>
        </div>
    );
};

export default PageContent;
