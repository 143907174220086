import { useCurrentUser } from "utilities/use-current-user";
import { useUserStateReconnectListener } from "./use-user-state-reconnect-listener/use-user-state-reconnect-listener";
import { useUserStateUpdateListener } from "./use-user-state-update-listener/use-user-state-update-listener";

/**
 * Listen for the UserStateUpdate event and update the user state cache accordingly. Also, toggle chat and email
 * accordingly. The UserStateUpdate event will be emitted by the backend whenever a user's state changes including in
 * another tab.
 */
export const useUserStateUpdate = () => {
    const { data: user } = useCurrentUser();
    useUserStateUpdateListener(user);
    useUserStateReconnectListener(user);
};
