import { Fragment, FunctionComponent, memo } from "react";

import InteractionType from "constants/interaction-type";
import { ampersandList } from "@edgetier/client-components";

import { IProps } from "./query-history-title-description.types";
import "./query-history-title-description.scss";

/**
 * Partial title for the interaction search thread modal.
 * @param props.interactionTypeIds All the interaction types in the thread.
 * @returns                        Title element.
 */
const QueryHistoryTitleDescription: FunctionComponent<IProps> = ({ interactionTypeIds }) => {
    return (
        <Fragment>
            {ampersandList(
                interactionTypeIds
                    .filter((item, index, items) => items.indexOf(item) === index)
                    .map((interactionTypeId) => InteractionType[interactionTypeId])
            )}
        </Fragment>
    );
};

export default memo(QueryHistoryTitleDescription);
