import { animated, config, useTransition } from "react-spring";
import { IProps } from "./animated-route-switch.types";
import { Switch, useLocation } from "react-router-dom";

/**
 * Animate switching routes.
 * @param props.children  Route content.
 * @param props.className Optional class name.
 * @returns               Animated route switching component.
 */
export function AnimatedRouteSwitch({ children, className }: IProps) {
    const location = useLocation();
    const transition = useTransition(location, {
        config: { ...config.stiff, clamp: true, mass: 0.6, friction: 5 },
        initial: null,
        keys: ({ pathname }) => pathname,
        from: { opacity: 0, transform: "translate3d(-5%, 0, 0)" },
        enter: { opacity: 1, transform: "translate3d(0%, 0, 0)" },
        leave: { opacity: 0 },
        exitBeforeEnter: true,
    });

    return (
        <div className={className}>
            {transition((style, item) => (
                <animated.div className="application__authenticated-routes__page" style={style}>
                    <Switch location={item}>{children}</Switch>
                </animated.div>
            ))}
        </div>
    );
}

export default AnimatedRouteSwitch;
