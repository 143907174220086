/**
 * Socket events. These are mapped to actions elsewhere.
 */

enum ChatEvent {
    Accept = "accept",
    Actions = "actions",
    ChatMessage = "chat_message",
    CustomerUrlChange = "customer_url_change",
    Disconnect = "disconnect",
    EndChat = "end_chat",
    ForceDisableChat = "force_disable_chat",
    ForceEnableChat = "force_enable_chat",
    ForceSignOut = "force_sign_out",
    Invite = "invite",
    InviteCancel = "invite_cancel",
    JoinAsObserver = "join_as_observer",
    LeaveAsObserver = "leave_as_observer",
    ProposedActivity = "proposed_activities",
    Reconnect = "reconnect",
    RefreshSid = "refresh_sid",
    Reject = "reject",
    SignIn = "sign_in",
    SignOut = "sign_out",
    Transfer = "transfer",
    TransferFailure = "transfer_failure",
    TransferSuccess = "transfer_success",
    Typing = "typing",
    UserStateUpdate = "user_state_update",
}

export default ChatEvent;
