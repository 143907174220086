import { Children, isValidElement, ReactElement } from "react";

import { IFilterFieldOption } from "./dynamic-filter-group.types";
import DynamicFilterField from "../dynamic-filter-field";

/**
 * Checks that the children are all form fields components
 * @param children      All children passed to the optional-form-group component
 */
export const getValidFormChildren = (children: React.ReactNode, hideFields: string[]) => {
    return Children.toArray(children)
        .filter(
            (child): child is ReactElement<IFilterFieldOption> =>
                isValidElement(child) && child.type === DynamicFilterField && "name" in child.props
        )
        .filter(({ props }) => !hideFields.includes(props.name));
};

/**
 * Check if value is object
 */
const isObject = (value: any) => {
    const type = typeof value;

    return type === "function" || (type === "object" && !!value);
};

/**
 * Check that form field has value
 */
export const formFieldHasValue = (fields: { [index: string]: any }, name: string) => {
    if (typeof fields === "undefined") {
        return false;
    }

    // Handle a single level of array nesting, e.g. selectOptionId.2
    const [propertyName, arrayIndex] = name.split(".");
    let valueOrArray = fields[propertyName];

    // Update value if array or object
    if (arrayIndex) {
        if (Array.isArray(valueOrArray)) {
            valueOrArray = valueOrArray[Number(arrayIndex)];
        }

        if (!Array.isArray(valueOrArray) && isObject(valueOrArray)) {
            valueOrArray = valueOrArray[arrayIndex];
        }
    }

    // false and 0 are valid falsey values
    if (valueOrArray === false || valueOrArray === 0) return true;

    if (valueOrArray && Array.isArray(valueOrArray)) {
        // Arrays must have at least one entry to be considered as having a value
        return Boolean(valueOrArray.length);
    }
    // finally, all remaining valid values are truthy
    return Boolean(valueOrArray);
};

/**
 * Sort components by selected option
 * @param children           All children passed to the optional-form-group component
 * @param orderArray         Array of selected filtters
 */
export const sortFilterComponents = (children: ReactElement[], orderArray: string[]) => {
    return children.sort((itemOne, itemTwo) => {
        const itemOneValue = itemOne.props.name;
        const ItemTwoValue = itemTwo.props.name;
        return orderArray.indexOf(itemOneValue) > orderArray.indexOf(ItemTwoValue) ? 1 : -1;
    });
};

/**
 * Get fields that aren't being used to add to an "Add filter" select input.
 * @param filterComponents All fields in the form.
 * @param visibleFields    Active fields.
 * @returns                Array of fields.
 */
export const getNotVisibleFields = (
    filterComponents: ReturnType<typeof getValidFormChildren>,
    visibleFields: string[]
) => {
    return filterComponents
        .map(({ props: { name, label } }) => ({ name, label }))
        .filter(({ name }) => !visibleFields.includes(name))
        .sort((one, two) => one.label.localeCompare(two.label));
};

/**
 * Get the fields that should be visible. Either through props they are explicitly stated as being visible, or they have
 * a value already.
 * @param filterComponents All fields in the form.
 * @param visibleFields    Active fields.
 * @param formValues       Current form values.
 * @returns                Visible fields.
 */
export const getVisibleFields = (
    filterComponents: ReturnType<typeof getValidFormChildren>,
    visibleFields: string[],
    formValues: { [index: string]: any }
) => {
    return visibleFields.concat(
        filterComponents
            .filter(({ props: { name, names } }) => {
                const alternateNames = names ?? [];
                const filterHasValue =
                    formFieldHasValue(formValues, name) ||
                    alternateNames.some((alternateName) => formFieldHasValue(formValues, alternateName));

                return !visibleFields.includes(name) && filterHasValue;
            })
            .map(({ props: { name } }) => name)
    );
};
