import { Url } from "@edgetier/types";
import { AxiosError } from "axios";
import useSimpleQuery, { useSimplePrefetch } from "queries/use-simple-query";
import { UseQueryOptions } from "react-query";
import { IUser } from "redux/application.types";
import urljoin from "url-join";
import { getUserId } from "./local-storage";

export const usePrefetchCurrentUser = () => {
    const userId = getUserId();
    useSimplePrefetch(urljoin(Url.Users, userId!.toString()));
};

/**
 * A query for requesting the current user from the backend.
 */
export const useCurrentUser = (configuration: UseQueryOptions<IUser, AxiosError> = {}) => {
    const userId = getUserId();
    return useSimpleQuery<IUser>(urljoin(Url.Users, userId!.toString()), {
        enabled: userId !== null,
        staleTime: 1000 * 60 * 5,
        ...configuration,
    });
};
