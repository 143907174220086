import { actions as toast, BasicToastrOptions } from "react-redux-toastr";
import { AnyAction } from "redux";
import Axios from "axios";
import { ThunkAction } from "redux-thunk";
import { getServerError } from "@edgetier/utilities";

import { IApplicationState } from "redux/types";

type IToastThunk<T = any> = ThunkAction<T, IApplicationState, void, AnyAction>;

interface IOptions extends BasicToastrOptions {
    removeOnHoverTimeOut: number;
}

const DEFAULT_OPTIONS: IOptions = { showCloseButton: true, removeOnHover: true, removeOnHoverTimeOut: 2500 };

/**
 * Show an error toast message. These are on display for longer than normal toasts.
 * @param title   Toast title.
 * @param message Optional toast message.
 */
export function showErrorToast(title: string, message?: string) {
    const options = { ...DEFAULT_OPTIONS, timeOut: 10000, removeOnHoverTimeOut: 5000 };
    return toast.add({ type: "error", title, message, options });
}

/**
 * Show an information toast message.
 * @param title   Toast title.
 * @param message Optional toast message.
 */
export function showInfoToast(title: string, message?: string) {
    return toast.add({ type: "info", title, message, options: DEFAULT_OPTIONS });
}

/**
 * Show a server error as a toast message.
 * @param title   Toast title.
 * @param message Optional toast message.
 */
export function showServerErrorToast(title: string, serverError: any): IToastThunk {
    return (dispatch) => {
        if (!Axios.isCancel(serverError) && Axios.isAxiosError(serverError)) {
            dispatch(showErrorToast(title, getServerError(serverError)));
        }
    };
}

/**
 * Show a success toast message.
 * @param title   Toast title.
 * @param message Optional toast message.
 */
export function showSuccessToast(title: string, message?: string) {
    return toast.add({ type: "success", title, message, options: DEFAULT_OPTIONS });
}

export default { showErrorToast, showInfoToast, showServerErrorToast, showSuccessToast };
