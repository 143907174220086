import classNames from "classnames";
import React from "react";
import { FunctionComponent, memo } from "react";
import { IProps } from "./table-menu-item.types";
import "./table-menu-item.scss";

/**
 * Show an item in a table menu list.
 * @param props.children Item content.
 * @param props.isActive If the item has been activated.
 * @returns              Menu item.
 */
const TableMenuItem: FunctionComponent<IProps> = ({ children, isActive, ...otherProps }) => {
    return (
        <button
            type="button"
            className={classNames(otherProps.className, "grid__menu-item", { "grid__menu-item--is-active": isActive })}
            {...otherProps}
        >
            {children}
        </button>
    );
};

export default memo(TableMenuItem);
