import Select from "~/select";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Fragment, FunctionComponent, memo, useCallback, useMemo, useState } from "react";
import { faPlus } from "@fortawesome/pro-solid-svg-icons";
import { IFilterFieldOption, IProps } from "./dynamic-filter-group.types";
import "./dynamic-filter-group.scss";
import {
    getValidFormChildren,
    getNotVisibleFields,
    getVisibleFields,
    sortFilterComponents,
} from "./dynamic-filter-group.utilities";
import { useFormikContext } from "formik";
import { defaultContext, DynamicFiltersContext } from "../dynamic-filters-context/dynamic-filters-context";
import { doNothing } from "@edgetier/utilities";

/**
 * Wrapper for optional fields, included fields will be presented in a dropdown and only rendered if:
 * - they have a value at initialisation
 * - they are selected via the dropdown select
 * - they are one of the initial default fields passed as a prop.
 * @param props.canAddMoreFilters    Boolean indicating if more filters can be added.
 * @param props.canRemoveFilters     Boolean indicating if more filters can be removed.
 * @param props.children             List of DynamicFilter fields
 * @param props.hideFields           Fields that cannot be added.
 * @param props.initialVisibleFields Fields that should be displayed by default.
 * @param props.onRemoveFilter       An optional callback that runs when a filter is removed.
 */
const DynamicFilterGroup: FunctionComponent<IProps> = ({
    canAddMoreFilters = true,
    canRemoveFilters = true,
    children,
    hideFields = [],
    initialVisibleFields = [],
    onRemoveFilter = doNothing,
}) => {
    const { values: formValues } = useFormikContext<{ [index: string]: any }>();
    const filterComponents = useMemo(() => getValidFormChildren(children, hideFields), [children, hideFields]);
    const [visibleFields, setVisibleFields] = useState<string[]>(
        getVisibleFields(filterComponents, initialVisibleFields, formValues)
    );

    // Fields not visible are available in a drop-down.
    const notVisibleFields = useMemo(() => getNotVisibleFields(filterComponents, visibleFields), [
        filterComponents,
        visibleFields,
    ]);

    /**
     * Order field components based on user selection
     */
    const sortedFieldComponents = useMemo(() => sortFilterComponents(filterComponents, visibleFields), [
        filterComponents,
        visibleFields,
    ]);

    /**
     * Handle form field selection, clear the selected field from all fields and put it in visible fields
     * @param selectedOption            Currently selected option
     */
    const handleSelectOptionalField = useCallback((selectedOption: any): void => {
        if (selectedOption) {
            setVisibleFields((previousFields) => [...previousFields, selectedOption.name]);
        }
    }, []);

    return (
        <DynamicFiltersContext.Provider
            value={{
                ...defaultContext,
                canRemoveFilters,
                notVisibleFields,
                onRemoveFilter,
                setVisibleFields,
                visibleFields,
            }}
        >
            {sortedFieldComponents
                .filter((fieldComponent) => visibleFields.includes(fieldComponent.props.name))
                .map((fieldComponent) => (
                    <Fragment key={fieldComponent.props.name}>{fieldComponent}</Fragment>
                ))}

            {canAddMoreFilters && notVisibleFields.length > 0 && (
                <div className="dynamic-filter-group__add-filter field field-inline">
                    <div className="field-inline__icon">
                        <FontAwesomeIcon icon={faPlus} />
                    </div>
                    <Select
                        getOptionLabel={(option: IFilterFieldOption) => option.label}
                        getOptionValue={(option: IFilterFieldOption) => option.name}
                        options={notVisibleFields}
                        onChange={handleSelectOptionalField}
                        noOptionsMessage={() => "No filters available"}
                        placeholder="Add filter&hellip;"
                        className="field react-select-container muted-field optional-form-group-select"
                        classNamePrefix="react-select"
                        value={null}
                    />
                </div>
            )}
        </DynamicFiltersContext.Provider>
    );
};

export default memo(DynamicFilterGroup);
