import React, { FunctionComponent } from "react";

import { IProps } from "./page-header.types";
import "./page-header.scss";

/**
 * Header with title above a page's content.
 * @param props.title       Name of the page.
 * @param props.subTitle
 * @param props.children
 * @returns     Page header.
 */
const PageHeader: FunctionComponent<IProps> = ({ children, title, subTitle }) => {
    return (
        <div className="page-header">
            <div className="page-header__title">
                {title && <h2>{title}</h2>}
                {subTitle && <h3>{subTitle}</h3>}
            </div>
            {children}
        </div>
    );
};

export default PageHeader;
