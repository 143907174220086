import React from "react";
import { connect } from "react-redux";
import urlJoin from "url-join";
import Axios, { CancelTokenSource } from "axios";
import { FormFieldType, Url } from "@edgetier/types";
import { addSeconds, differenceInMilliseconds, parseISO } from "date-fns";

import { chatOperations } from "redux/modules/chat";
import { loadingBlockerOperations } from "redux/modules/loading-blocker";
import { IApplicationState } from "redux/types";
import InteractionType from "constants/interaction-type";
import { IChatQuery, IChatInvite } from "redux/modules/chat/chat.types";
import { ISettings } from "redux/application.types";
import { connectSocket, disconnectSocket, getSocket } from "redux/modules/chat/socket";
import ChatEvent from "redux/modules/chat/chat-event";
import HandlingType from "constants/handling-type";
import axios from "utilities/axios";
import { refreshSid, enableChat } from "utilities/enable-chat";
import acceptChat from "utilities/accept-chat";
import { showNotification } from "utilities/browser-notifications";
import requestCurrentUser from "utilities/request-current-user";
import notificationSound from "sounds/Notification3_1.mp3";
import customerLeaving from "sounds/customerLeaving.mp3";

import { InteractionDecorator } from "../utilities/interaction-decorator";
import { requestAgentInformation } from "../utilities/common-interaction-decorators";

import { IProps, IChatWrapUpSeconds, IStateProps, IChatSummary } from "./chat-service.types";
import { toastOperations } from "redux/modules/toast";
import { CHAT_DISABLED_SECOND_WINDOW } from "constants/chat-second-window-message";

const invitationSound = new Audio(notificationSound);
const customerLeavingSound = new Audio(customerLeaving);

export class ChatService extends React.PureComponent<IProps> {
    chatWrapUpDeadlines: { [index: string]: number } = {};
    cancelTokenSource = Axios.CancelToken.source();

    // Each chat causes a number of backend requests. These can be cancelled by key if necessary.
    chatSubRequestCancelTokens: { [index: string]: CancelTokenSource } = {};

    decorator = new InteractionDecorator<IChatSummary>();

    componentDidMount(): void {
        this.connectSocket();

        this.decorator.registerListener(
            ({ hasAgentInformation }) => !hasAgentInformation,
            (chat) =>
                requestAgentInformation<IChatSummary>(
                    chat,
                    this.decorator.getInteractionConfiguration(chat.key),
                    this.updateChat,
                    this.props.onServerError
                )
        );
    }

    componentDidUpdate(previousProps: IProps): void {
        // Maybe trigger timers to automatically wrap-up chats.
        this.cancelChatWrapUpTimers(previousProps.pendingAutomaticChatWrapUps, this.props.pendingAutomaticChatWrapUps);
        this.startChatWrapUpTimers(previousProps.chatWrapUpSeconds, this.props.chatWrapUpSeconds);

        this.decorator.update(previousProps.chats, this.props.chats);
    }

    componentWillUnmount(): void {
        // Clear all timeouts.
        Object.values(this.chatWrapUpDeadlines).forEach(window.clearTimeout);
        this.decorator.cancelAll();

        this.props.cleanUpChats();

        // Cancel all other requests.
        this.cancelTokenSource.cancel();

        // Close the socket as the user is signing out.
        disconnectSocket();
    }

    /**
     * Connect to the socket and register all socket event handlers.
     */
    async connectSocket() {
        const socket = await getSocket();
        await connectSocket();
        socket.on(ChatEvent.EndChat, this.onEndChat);
        socket.on(ChatEvent.Invite, this.onInviteEvent);
        socket.io.on(ChatEvent.Reconnect, this.onReconnect);
        socket.on(ChatEvent.CustomerUrlChange, this.props.storeCustomerUrl);
        socket.on(ChatEvent.ChatMessage, this.props.storeIncomingMessage);
        socket.on(ChatEvent.Disconnect, () => {
            this.props.toggleConnected({ isConnected: false });
        });
        socket.on(ChatEvent.ForceEnableChat, this.props.toggleChatOn);
        socket.on(ChatEvent.ForceSignOut, this.onForceDisableChat);
        socket.on(ChatEvent.ForceDisableChat, this.onForceDisableChat);
        socket.on(ChatEvent.InviteCancel, this.props.removeWaitingChat);
        socket.on(ChatEvent.ProposedActivity, this.props.setUpProposedActivities);
        socket.on(ChatEvent.TransferSuccess, this.props.removeTransferredChat);
        socket.on(ChatEvent.TransferFailure, this.props.reportTransferFailure);
        socket.on(ChatEvent.Typing, this.props.updateTyping);
    }

    /**
     * If chat is forced to be disabled, toggle hasBeenForceDisabled in redux, toggle chat off, leave all active chats
     * and show an info toast to the user.
     */
    onForceDisableChat = () => {
        this.props.setHasBeenForceDisabled({ hasBeenForceDisabled: true });
        this.props.toggleChat(false);
        this.props.showInfoToast("Chat Disabled", CHAT_DISABLED_SECOND_WINDOW);
        this.props.chats.forEach((chat) => {
            this.props.leaveChat(chat.chatToken);
        });
    };

    /**
     * Record that a chat has ended. This can happen when a customer leaves or the system terminates a chat.
     * @param payload.chatToken Identifier for the chat being ended.
     */
    onEndChat = ({ chatToken }: { readonly chatToken: string }): void => {
        this.props.clearActivityTimeouts(chatToken);
        this.props.updateChat({ chatToken, data: { active: false } });
        try {
            customerLeavingSound.play();
        } catch {
            // The browser is blocking the sound but nothing can be done here.
        }
    };

    /**
     * When an agent receives an invite, store it, play a sound, and if they're not on the chat screen display a browser
     * notification. Clients can decide to have invites automatically accepted.
     * @param payload Invite payload from the backend.
     */
    onInviteEvent = async (payload: IChatInvite): Promise<void> => {
        if (!this.props.isChatConnected) {
            return;
        }

        try {
            const configuration = { cancelToken: this.cancelTokenSource.token };
            const { data: settings } = await axios.get<ISettings>(Url.Settings, configuration);
            const { data: user } = await requestCurrentUser(configuration);
            this.props.updateChatSettings(settings, user);
            if (settings.automaticallyAcceptInvites === false || payload.transferDetails !== null) {
                this.props.storeInvite(payload);

                // Show a notification if the agent is on another screen.
                const expiresIn = differenceInMilliseconds(new Date(), parseISO(payload.expires));
                // isChatFocused can be true while in a differnet browser tab so visibilityState should be checked.
                if (!this.props.isChatFocussed || document.visibilityState === "hidden") {
                    showNotification(`${payload.customerName} is waiting to chat.`, expiresIn * 0.5);
                }
            } else {
                // Accept the chat automatically and show a notification.
                await acceptChat(payload.chatToken, payload.languageId, this.props.enterChat, configuration);
                if (!this.props.isChatFocussed || document.visibilityState === "hidden") {
                    showNotification(`Started chat with ${payload.customerName}.`, 5000);
                }
            }
        } catch (error) {
            this.props.onServerError(error);
        }

        try {
            await invitationSound.play();
        } catch {
            // The browser is blocking the sound but nothing can be done here.
        }
    };

    /**
     * Reconnect the socket and request any existing chats that the agent is already handling.
     */
    onReconnect = async (): Promise<void> => {
        const configuration = { cancelToken: this.cancelTokenSource.token };
        // The socket is now connected.
        this.props.toggleConnected({ isConnected: true });
        this.props.showLoadingBlocker(true);

        try {
            // If the agent had chat enabled before, it should be enabled again for them.
            if (this.props.isChatEnabled) {
                await enableChat();
            }

            // Get and store the chats that the agent is currently working on.
            const chats = await this.props.requestActiveChats(configuration);

            // In the case where an agent was handling chats but with chat disabled, the backend needs to know their new
            // socket ID after reconnection.
            if (this.props.isChatEnabled === false && Array.isArray(chats) && chats.length > 0) {
                await refreshSid();
            }
        } catch (serverError) {
            if (Axios.isAxiosError(serverError)) {
                this.props.onServerError(serverError);
            }
        } finally {
            this.props.hideLoadingBlocker(true);
        }
    };

    /**
     * Update either a chat or email with some extra details.
     * @param interaction Details of the interaction to update.
     * @param data        Partial chat or email data.
     */
    updateChat = ({ key }: IChatSummary, data: Partial<IChatQuery>): void => {
        const chatData = data as Partial<IChatQuery>;
        this.props.updateChat({ chatToken: key, data: chatData });
    };

    /**
     * When chats are ended and the setting to automatically wrap-up chats is enabled, a timer is started to trigger the
     * wrap-up at some point in the future.
     * @param previousChats Chat details before a props update.
     * @param currentChats  Chat details after a props update.
     */
    startChatWrapUpTimers(previousChats: IChatWrapUpSeconds[], currentChats: IChatWrapUpSeconds[]): void {
        const oldChatTokens = previousChats.map(({ chatToken }) => chatToken);

        // Find any newly added chats.
        currentChats
            .filter(({ chatToken }) => !oldChatTokens.includes(chatToken))
            .forEach(({ automaticChatWrapUpSeconds, chatToken }) => {
                // Clear any existing timers. This can happen if the deadline is extended.
                if (typeof this.chatWrapUpDeadlines[chatToken] === "number") {
                    window.clearTimeout(this.chatWrapUpDeadlines[chatToken]);
                }

                // Start a new countdown to the automatic wrap-up.
                const time = automaticChatWrapUpSeconds * 1000;
                const timeout = window.setTimeout(() => this.automaticallyWrapUpChat(chatToken), time);
                this.chatWrapUpDeadlines[chatToken] = timeout;
                const wrapUpDeadline = addSeconds(new Date(), automaticChatWrapUpSeconds);
                this.props.updateChat({ chatToken, data: { wrapUpDeadline } });
            });
    }

    /**
     * If automatic chat wrap-ups are enabled, wrap up a chat after the wait period has expired.
     * @param chatToken Identifier for the chat to wrap-up.
     */
    async automaticallyWrapUpChat(chatToken: string): Promise<void> {
        try {
            // Add a parameter to the request so the backend knows this is an automatic wrap-up.
            const data = { handlingTypeId: HandlingType.AutomaticWrapUp };
            const configuration = { cancelToken: this.cancelTokenSource.token, data };
            this.props.updateChat({ chatToken, data: { isInAutomaticWrapUp: true } });
            await axios.delete(urlJoin(Url.Chat, chatToken), configuration);

            // The chat can now be removed from the agent's screen.
            this.props.leaveChat(chatToken);

            // Forget the timeout.
            delete this.chatWrapUpDeadlines[chatToken];
        } catch (serverError) {
            this.props.updateChat({ chatToken, data: { isInAutomaticWrapUp: false } });
            if (Axios.isAxiosError(serverError)) {
                this.props.onServerError(serverError);
            }
        }
    }

    /**
     * When a wrap-up deadline is removed or the chat has been ended, cancel any automatic wrap-ups that have been
     * scheduled. This can happen when an agent manually ends a chat while the automatic wrap-up timer is running.
     * @param previousPendingChats Chat tokens of chats that were showing the wrap-up timer.
     * @param currentPendingChats  Chat tokens of chats that now are showing the wrap-up timer.
     */
    cancelChatWrapUpTimers(previousPendingChats: string[], currentPendingChats: string[]): void {
        const chatTokensGone = previousPendingChats.filter((chatToken) => !currentPendingChats.includes(chatToken));
        chatTokensGone.forEach((chatToken) => {
            if (typeof this.chatWrapUpDeadlines[chatToken] === "number") {
                window.clearTimeout(this.chatWrapUpDeadlines[chatToken]);
                delete this.chatWrapUpDeadlines[chatToken];
            }
        });
    }

    render() {
        return null;
    }
}

/**
 * See if the setup data has been downloaded and if the user is signed in.
 * @param state Application state.
 * @returns     Setup data and sign in state.
 */
export function mapStateToProps({ chat }: IApplicationState): IStateProps {
    // Format chats into summary format.
    const chatsArray = Object.values(chat.chats);
    const chats = chatsArray.map((item) => {
        const emailField = item.formSubmission.find((field) => field.formField.formFieldTypeId === FormFieldType.Email);
        return {
            bookingId: item.bookingId,
            chatToken: item.chatToken,
            emailAddress:
                typeof emailField === "undefined" || typeof emailField.value !== "string" ? null : emailField.value,
            hasAgentInformation:
                typeof item.bookingDetails !== "undefined" && typeof item.textTemplateVariables !== "undefined",
            id: item.chatToken,
            interactionDetailId: item.interactionDetailId,
            interactionTypeId: InteractionType.Chat as InteractionType.Chat,
            key: item.chatToken,
        };
    });

    // Determine if there are any chats that may need to be automatically wrapped up.
    const chatWrapUpSeconds = chatsArray
        .filter(
            ({ active, settings, wrapUpDeadline }) =>
                !active && settings.automaticChatWrapUp && !(wrapUpDeadline instanceof Date)
        )
        .map(({ chatToken, settings }) => ({
            chatToken,
            automaticChatWrapUpSeconds: settings.automaticChatWrapUpSeconds,
        }));

    return {
        chatWrapUpSeconds,
        chats,
        isChatEnabled: chat.enabled,
        isChatConnected: chat.isConnected,
        pendingAutomaticChatWrapUps: chatsArray
            .filter(({ wrapUpDeadline }) => wrapUpDeadline instanceof Date)
            .map(({ chatToken }) => chatToken),
    };
}

const mapDispatchToProps = {
    ...chatOperations,
    ...loadingBlockerOperations,
    ...toastOperations,
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatService);
