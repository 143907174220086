import React from "react";

import { IProps } from "./details-field-title.types";
import "./details-field-title.scss";

/**
 * Display a title above a booking details field.
 * @param children Content of the title.
 * @returns        Title component.
 */
const DetailsFieldTitle = ({ children }: IProps) => <div className="details-field-title">{children}</div>;

export default DetailsFieldTitle;
